<template>
  <p @click="changeServer">
    <strong>{{ $t('therms.data-server') }} : </strong>{{ server }}
  </p>
</template>

<script>
import { mapGetters } from 'vuex'
import { NAME_SERVER } from '@/router/routes/auth'
import { Dialog } from '@capacitor/dialog'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({ server: 'server/getCleanServer' }),
  },
  methods: {
    /**
     * @returns {Promise<void>}
     */
    async changeServer() {
      const { value } = await Dialog.confirm({
        title: this.$t('therms.data-server').toString(),
        message: this.$t('actions.change-data-server.message').toString(),
        okButtonTitle: this.$t('therms.confirm').toString(),
        cancelButtonTitle: this.$t('therms.cancel').toString(),
      })
      if (value) {
        await this.$router.push({ name: NAME_SERVER })
      }
    },
  },
}
</script>
